<template>
    <div>
        <Navbar 
            :page="`Diagnóstico ${(diagnostico && diagnostico.diag_base ? diagnostico.diag_base.tema : '') + ' - ' + (diagnostico && diagnostico.destinatarioAgente ? diagnostico.destinatarioAgente.nome : (diagnostico && diagnostico.destinatarioPessoa ? diagnostico.destinatarioPessoa.nome : ''))}`"
            :link="`/diagnosticos/visualizar/${$route.params.id}`" nameLink="Visualizar diagnósticos aplicado" 
        />
        <div v-if="diagnostico" class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="my-2">
                Status:
                    
                <select v-model="diagnostico.status" name="grauDerisco" id="grauDerisco" 
                    class="text-black py-2 w-44 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                    <option value="Em andamento">Em andamento</option>
                    <option value="Finalizado">Finalizado</option>
                </select>
            </div>

            <div class="bg-gray-200 py-2 px-4 rounded">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-6">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Questão</label>
                    </div>
                    <div class="col-span-4">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Resposta</label>
                    </div>
                    <div class="col-span-2">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">Resposta</label>
                    </div>
                </div>
            </div>

            <div v-for="(item, idx) in perguntas" :key="item._id" class="border-b-2 border-gray-200 py-2 px-4 mb-2">
                <div class="grid grid-cols-12 gap-2">
                    <div class="col-span-12 md:col-span-6">
                        <label class="text-left text-sm font-medium text-black"><b>{{ idx + 1 }} - </b>{{ item.pergunta }}</label>
                    </div>
                    <div class="col-span-12 md:col-span-4">
                        <div class="mt-2 w-full">
                            <label :for="`pergunta-${idx}-Sim`" class="inline-flex items-center">
                                <input type="radio" class="form-radio" :for="`pergunta-${idx}-Sim`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Sim">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Sim</span>
                            </label>
                            <label :for="`pergunta-${idx}-Nao`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${idx}-Nao`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Não">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não</span>
                            </label>
                            <label :for="`pergunta-${idx}-Naoaplica`" class="inline-flex items-center ml-1 md:ml-2">
                                <input type="radio" class="form-radio" :for="`pergunta-${idx}-Naoaplica`" :name="`pergunta-${idx}`" v-model="item.resposta" value="Não se aplica">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Não se aplica</span>
                            </label>
                            <label :for="`pergunta-${idx}-Outro`" class="inline-flex items-center ml-1 md:ml-2">
                                <input :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" type="checkbox" class="form-radio" :for="`pergunta-${idx}-Outro`" :name="`pergunta-${idx}`" v-model="item.comobs" value="Outro">
                                <span class="ml-1 md:ml-2 text-xs md:text-sm_2">Observações</span>
                            </label>
                            <textarea :class="{'opacity-50': diagnostico.status === 'Finalizado'}" :disabled="diagnostico.status === 'Finalizado'" rows="3" type="text" v-if="item.comobs" v-model="item.outroResposta" placeholder="Resposta" :name="`pergunta-${idx}-Outro-Resposta`" class="block mt-2 focus:ring-yellow-400 focus:border-yellow-400 w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl"/>
                        </div>
                    </div>
                    <div class="col-span-12 md:col-span-2">
                        <label class="text-left text-xs font-medium text-gray-500 tracking-wider">{{ item.resposta }}</label>
                        <button type="button" @click="addEvidencia(idx)" class="block mt-2 justify-center py-1 px-2 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                            Adicionar evidência
                        </button>
                    </div>
                    <div class="col-span-12 md:col-span-12">
                        <table v-if="item.evidencias && item.evidencias.length" class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Evidências</th>
                                <th scope="col" class="px-2 py-2 text-left text-xs font-medium text-gray-500 tracking-wider">Opções</th>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr v-for="(doc, index) in item.evidencias" :key="doc.filename">
                                <td class="px-2 py-2 text-sm whitespace-nowrap">
                                    {{doc.originalname}}
                                </td>
                                <td class="px-2 py-2 whitespace-nowrap">
                                    <a 
                                    type="button"
                                    target="_blank"
                                    :href="`${url_api}/upload?mimetype=${doc.mimetype}&filename=${doc.filename}&folder=empresas`"
                                    class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400">
                                    Visualizar
                                    </a>
                                    <button @click="excluirEvidencia(idx, index)" class="mr-1 inline-flex justify-center py-1 px-3 border border-transparent shadow-sm text-xs_3 font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-red-400" type="button">Excluir</button>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-show="item.resposta === 'Não' && diagnostico.tipoDiagnostico === 'interno'">
                    <div class="flex bg-slate-300 p-4 justify-between" >
                        <div class="flex">
                            <svg class="mr-2 fill-black" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256"><path d="M236.8,188.09,149.35,36.22h0a24.76,24.76,0,0,0-42.7,0L19.2,188.09a23.51,23.51,0,0,0,0,23.72A24.35,24.35,0,0,0,40.55,224h174.9a24.35,24.35,0,0,0,21.33-12.19A23.51,23.51,0,0,0,236.8,188.09ZM222.93,203.8a8.5,8.5,0,0,1-7.48,4.2H40.55a8.5,8.5,0,0,1-7.48-4.2,7.59,7.59,0,0,1,0-7.72L120.52,44.21a8.75,8.75,0,0,1,15,0l87.45,151.87A7.59,7.59,0,0,1,222.93,203.8ZM120,144V104a8,8,0,0,1,16,0v40a8,8,0,0,1-16,0Zm20,36a12,12,0,1,1-12-12A12,12,0,0,1,140,180Z"></path></svg>
                            <span class="mt-1">
                                Registro de vulnerabilidades ou não conformidades para o registro de plano de ação integral - momento 1
                            </span>
                        </div>

                        <svg @click="item.visivel = !item.visivel" class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#fff" viewBox="0 0 256 256"><path d="M224,128a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,128ZM101.66,53.66,120,35.31V96a8,8,0,0,0,16,0V35.31l18.34,18.35a8,8,0,0,0,11.32-11.32l-32-32a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32Zm52.68,148.68L136,220.69V160a8,8,0,0,0-16,0v60.69l-18.34-18.35a8,8,0,0,0-11.32,11.32l32,32a8,8,0,0,0,11.32,0l32-32a8,8,0,0,0-11.32-11.32Z"></path></svg>
                    </div>
                    <div v-show="item.visivel" class="bg-slate-200 grid grid-cols-1 md:col-span-1 lg:grid-cols-7 text-sm">
                        <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 col-span-1 md:col-span-1 lg:col-span-3 items-end">
                            <div class="p-4 flex flex-col">
                                1 - Vulnerabilidades ou não conformidades
                                
                                <multiselect 
                                v-model="item.vulnerabilidades" 
                                :options="item.vulnerabilidades" 
                                :multiple="true" 
                                :close-on-select="false" 
                                :taggable="true" 
                                @tag="adicionarVulnerabilidade(idx, $event)" 
                                id="vulnerabilidade" 
                                placeholder="Digite e aperte Enter para adicionar" 
                                tagPlaceholder="" 
                                noOptionsText="" 
                                selectedLabel="" 
                                deselectLabel="" 
                                selectLabel="" 
                                class="shadow-sm sm:text-sm border-gray-300 rounded-md w-full lg:max-w-96"
                                />
                            </div>
                            <div class="p-4 flex flex-col">
                                2 - Condutas / sugestões a ser(em) implementada(s)
                                
                                <multiselect 
                                v-model="item.respostaNao" 
                                :options="item.respostaNao" 
                                :multiple="true" 
                                :close-on-select="false" 
                                :taggable="true" 
                                @tag="adicionarRespostaNao(idx, $event)" 
                                id="respostaNao" 
                                placeholder="Informe a conduta ou sugestão" 
                                tagPlaceholder="" 
                                noOptionsText="Nenhuma opção disponível" 
                                selectedLabel="" 
                                deselectLabel="" 
                                selectLabel="" 
                                class="shadow-sm sm:text-sm border-gray-300 rounded-md w-full"
                                />
                            </div>
                        </div>
                        <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 items-end col-span-1 md:col-span-1 lg:col-span-2">
                            <div class="p-4 flex flex-col">
                                3 - Grau de risco
                                <select v-model="item.pai.grauDerisco" name="grauDerisco" id="grauDerisco" 
                                    class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                    <option value="Muito baixo">Muito baixo</option>
                                    <option value="Baixo">Baixo</option>
                                    <option value="Médio">Médio</option>
                                    <option value="Alto">Alto</option>
                                    <option value="Muito Alto">Muito Alto</option>
                                </select>
                            </div>
                            <div class="p-4 flex flex-col">
                                4 - Condutas aprovadas
                                <select v-model="item.pai.condutaAprovada" name="condutaAprovada" id="condutaAprovada" 
                                    class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                    <option value="Sim">Sim</option>
                                    <option value="Não">Não</option>
                                    <option value="Parcialmente">Parcialmente</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex col-span-1 md:col-span-1 lg:col-span-2 items-end">
                            <div class="p-4 flex flex-col w-full">
                                5 - Quem aprovou
                                <input v-model="item.pai.quemAprovou" placeholder="Digite o nome" type="text" class="text-black py-1.5 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                            </div>  
                        </div>
                        <div class="flex flex-wrap md:flex-nowrap col-span-1 md:col-span-1 lg:col-span-7">
                            <div class="p-4 flex flex-col">
                                6 - Prazo de execução
                                <div class="flex  flex-wrap md:flex-nowrap space-x-1">
                                    <input v-model="item.pai.prazoDeExecucao.de" type="date" class="mt-2 block shadow-sm sm:text-sm border-gray-300 rounded-md text-gray-900">
                                    <input v-model="item.pai.prazoDeExecucao.ate" type="date" class="mt-2 block shadow-sm sm:text-sm border-gray-300 rounded-md text-gray-900">
                                </div>
                            </div>
                            <div class="p-4 flex flex-col w-full">
                                7 - Observações
                                <textarea v-model="item.pai.obs" class="rounded-md border-gray-300 sm:text-sm text-gray-900" name="obs" id="obs"></textarea>
                            </div>
                        </div>
                    </div>
                    
                    <div v-show="item.visivel" class="bg-slate-200 flex flex-col md:flex-row text-sm">
                        <div class="flex flex-col p-4 w-full" id="multiselect_responsaveis_conduta">
                            8 - Responsaveis pela conduta

                            <multiselect 
                                v-model="item.pai.responsaveisPelaConduta" 
                                :options="agentesProfissionaisPessoas" 
                                :multiple="true" 
                                group-values="arr" group-label="tipo"
                                :close-on-select="false" 
                                placeholder="Digite e aperte Enter para adicionar" 
                                tagPlaceholder="" 
                                noOptionsText="" 
                                selectedLabel="" 
                                deselectLabel="" 
                                selectLabel="" 
                                class="shadow-sm sm:text-sm border-gray-300 rounded-md "
                                track-by="_id" label="nome"
                            >
                                <template #tag="props">
                                    <span class="option__desc space-x-1">
                                        <span :class="
                                            {
                                                'bg-yellow-400': props.option.tipo === 'Fornecedor',
                                                'bg-blue-500': props.option.tipo === 'Profissional',
                                                'bg-green-500': props.option.tipo === 'Pessoa',
                                            }
                                        " class="mx-1 p-1 text-white rounded-md">
                                            {{ props.option.nome }}
                                        </span>
                                    </span>
                                </template>

                                <template #option="props">
                                    <div 
                                        :class="
                                            {
                                                'bg-yellow-400': props.option.tipo === 'Fornecedor',
                                                'bg-blue-500': props.option.tipo === 'Profissional',
                                                'bg-green-500': props.option.tipo === 'Pessoa',
                                            }
                                        "

                                        class="option__desc p-1"
                                    >
                                        <span class="option__title" v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
                                        <span class="option__title" v-if="!props.option.$isLabel">{{ props.option.nome }}</span>
                                    </div>
                                </template>

                                <template #noResult>
                                    Não encontrou
                                </template>
                            </multiselect>
                        </div>
                        <div class="p-4 flex flex-col">
                            9 - Status
                            <select v-model="item.pai.status" name="status" id="status" @change="gerarDataConclusao(item.pai.status, idx)"
                                class="text-black py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm">
                                <option value="Pendente">Pendente</option>
                                <option value="Em andamento">Em andamento</option>
                                <option value="Concluído">Concluído</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="grid grid-cols-12">
                <div class="col-span-6">
                    <button @click="$router.push({ path: `/diagnosticos/visualizar/${$route.params.id}`})" v-if="$store.state.user._id" type="button" class="inline-flex justify-center mr-2 py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                    <button 
                        v-if="diagnostico && !diagnostico.finalizado && $store.state.user._id" 
                        @click="salvar()" 
                        type="button" 
                        class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-400"
                    >
                        Salvar
                    </button>
                </div>
            </div>
        <modal name="sucessoFinalizar" :adaptive="true" :height="'auto'">
            <div id="idmodal" class="px-3 py-4 overflow-auto h-full md:h-auto">
                <h1 class="font-r font-semibold text-2xl mb-4">Questionário finalizado com sucesso!</h1>
                <button @click="$modal.hide('sucessoFinalizar')" class="text-white hover:bg-gray-600 bg-green-500 rounded py-2 px-12">
                    OK
                </button>
            </div>
        </modal>
        <modal name="addEvidencia" :width="800" :height="'auto'" :adaptive="true" :reset="true">
            <div class="px-3 py-2 overflow-y-auto h-full">
                <h2 class="text-base font-semibold mb-2"> 
                    Adicionar documento 
                </h2>
                <vue-dropzone 
                    ref="doc" id="doc" 
                    class="w-full" 
                    :options="dropzoneOptions" 
                    v-on:vdropzone-success="update">
                </vue-dropzone>
            </div>
        </modal>
        </div>
    </div>
</template>


<script>
import vueDropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from 'moment'
export default {
    components: {
      vueDropzone,
    },
    data() {
        return {
            route: '/diagnosticos',
            diagnostico: null,
            perguntas: [],
            itemAddEvidencia: null,
            dropzoneOptions: this.$http.getDropzoneConfig(
            `upload`,
            'post',
            {
              maxFiles: 1,
              addRemoveLinks: true,
              capture: true,
              dictDefaultMessage: 'Clique ou arraste o arquivo aqui',
              dictRemoveFile: 'Remover'
            }
            ),
            agentesProfissionaisPessoas: [],
        }
    },
    methods: {
        adicionarRespostaNao(idx, novoValor) {
            const respostaNao = this.diagnostico.perguntas[idx].respostaNao;
            if (!respostaNao.includes(novoValor)) respostaNao.push(novoValor); 
        },
        adicionarVulnerabilidade(idx, novoValor) {
            const vulnerabilidades = this.diagnostico.perguntas[idx].vulnerabilidades;
            if (!vulnerabilidades.includes(novoValor)) vulnerabilidades.push(novoValor); 
        },
        gerarDataConclusao(status, idx){
            if(status === 'Concluído'){
                this.diagnostico.perguntas[idx].pai.concluidoEm = new Date();
            }else{
                this.diagnostico.perguntas[idx].pai.concluidoEm = null;
            } 
        },
        async start(){

            const reqForProPess = await this.$http.post(`/v1/diagnosticoAplicado/forpropess`)
            console.log(reqForProPess)
            this.agentesProfissionaisPessoas = reqForProPess.data
            const id = this.$route.params.idAplicado;
            
            if (id) {
                await this.$http.get(`/v1/diagnosticoAplicado/${id}`)
                .then(resp=>{

                    resp.data.perguntas.map(p => {
                        p.pai.prazoDeExecucao.de = moment(p.pai.prazoDeExecucao.de).format("YYYY-MM-DD")
                        p.pai.prazoDeExecucao.ate = moment(p.pai.prazoDeExecucao.ate).format("YYYY-MM-DD")
                    })

                    this.diagnostico = resp.data;
                    this.perguntas = resp.data.perguntas;
                })
                .catch(()=> {
                    this.$vToastify.error("Perguntas não encontradas.");
                    this.$router.push({ path: this.route})
                })
            }
        },
        async salvar(status) {
            this.perguntas.map(p => {
                p.pai.prazoDeExecucao.de = moment(p.pai.prazoDeExecucao.de).startOf("day").toDate()
                p.pai.prazoDeExecucao.ate = moment(p.pai.prazoDeExecucao.ate).startOf("day").toDate()
            })

            if(status)  this.diagnostico.status = status
            this.diagnostico.perguntas = [...this.perguntas]
            await this.$http.put(`v1/diagnosticoAplicado/`,  this.diagnostico)
            .then(() => {
                this.$vToastify.success(status ? 'Diagnóstico finalizado':'Diagnóstico salvo')

                this.perguntas.map(p => {
                    p.pai.prazoDeExecucao.de = moment(p.pai.prazoDeExecucao.de).format("YYYY-MM-DD")
                    p.pai.prazoDeExecucao.ate = moment(p.pai.prazoDeExecucao.ate).format("YYYY-MM-DD")
                })
            })
            .catch(()=>this.$vToastify.error('Erro ao salvar diagnóstico'))
        },
        async update(file, response) {
            if(response.file) this.perguntas[this.idxAddEvidencia].evidencias.push(response.file);
            this.$refs.doc.removeFile(file);
            this.itemAddEvidencia = null;
            this.$modal.hide('addEvidencia');
        },
        excluirEvidencia(idxPergunta, index) {
            if(this.perguntas[idxPergunta] && this.perguntas[idxPergunta].evidencias) this.perguntas[idxPergunta].evidencias.splice(index, 1);
        },
        addEvidencia(idx){
            this.idxAddEvidencia = idx;
            this.$modal.show('addEvidencia');
        }
    },
    async beforeMount() {
        this.start();
    },
}
</script>